const rimUrls = {
  sb: 'https://sb-roche-r8-rim-dev.veevavault.com',
  dev: 'https://sb-roche-r8-rim-dev.veevavault.com',
  qa: 'https://sb-roche-rim-validation.veevavault.com',
  training: 'https://sb-roche-rim-validation.veevavault.com',
  prod: 'https://roche-regulatory.veevavault.com',
};
const vqdUrls = {
  sb: 'https://sb-roche-qms-ptq.veevavault.com',
  dev: 'https://sb-roche-qms-ptq.veevavault.com',
  qa: 'https://sb-roche-quality-test---pharma.veevavault.com',
  training: 'https://sb-roche-quality-test---pharma.veevavault.com',
  prod: 'https://roche-quality-pharma.veevavault.com/',
};

type Environments = 'sb' | 'dev' | 'qa' | 'training' | 'prod';

export const getDocumentUrlInSystem = (system: string, documentId: string) => {
  console.log(process.env.REACT_APP_ENV);
  const env = (process.env.REACT_APP_ENV as Environments) || 'dev';
  if (system === 'Veeva RIM') {
    return `${rimUrls[env]}/ui/#doc_info/${documentId}`;
  }
  if (system === 'VeeVa QualityDocs') {
    return `${vqdUrls[env]}/ui/#doc_info/${documentId}`;
  }
};
