import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { GlobalState } from '../../core/models/state/globalState';
import {
  FieldsFilledInterface,
  FormValues,
  SelectValue,
  Member,
  IdLabel,
} from '../../core/models/global';

import { createTemplate, getTemplateMetadataValues } from '../../state/actions/template';
import IAButton from '../../components/Button/Button';
import './Template.scss';
import TemplateData from '../../components/TemplateSteps/TemplateData';
import BasicDetails from '../../components/TemplateSteps/BasicDetails';
import Review from '../../components/TemplateSteps/Review';
import TemplateExistsDialog from '../../components/TemplateSteps/TemplateExistsDialog';
import Stepper from '../../components/Stepper/Stepper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PageTitle from '../../components/PageTitle/PageTitle';
import { FIRST_STEP, SECOND_STEP, TEMPLATE_OPTIONAL_FIELDS, THIRD_STEP } from '../../constants';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { actionButtonContentAndAction, isFieldEmpty } from '../../core/services/helpers';
import { CountryGoup, TEMPLATE_TYPES } from '../../core/models/template/template';
import { updateCountryGroupsValues } from '../../state/actions/system';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';

export const Template = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: GlobalState) => state.user);
  const [resetValuesBasedOnType, setResetValuesBasedOnType] = useState<boolean | string>(false);
  const [templateExistDialog, setTemplateExistDialog] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(FIRST_STEP);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [fieldsFilled, setFieldsFilled] = useState<FieldsFilledInterface>({});
  const [saving, setSaving] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [emptyFields, setEmptyFields] = useState<string[]>([]);
  const [nextStepClicked, setNextStepClicked] = useState(false);
  const steps = ['Enter basic details', 'Enter template data', 'Review & Save'];
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [countryGroups, setCountryGroups] = useState<CountryGoup[]>([]);

  const saveTemplate = () => {
    setSaving(true);
    const effectiveDate = (fieldsFilled[FIRST_STEP] as FormValues)?.effectiveDate as string;
    const members = ((fieldsFilled[FIRST_STEP] as FormValues).members as Member[]) || [];

    let authorList = members
      .filter((member) => member.role.toLowerCase().includes('author'))
      .map((el) => el.value);
    if (!authorList.includes(user.userId)) {
      authorList = [...authorList, user.userId];
    }

    let reviewerList = members
      .filter((member) => member.role.toLowerCase().includes('reviewer'))
      .map((el) => el.value);
    reviewerList = reviewerList.filter((el) => el !== user.userId);

    const FCUSTCOUNTRYGROUP2 = countryGroups
      .filter((countryGroup) => typeof countryGroup.countryGroupName !== 'string')
      .map((countryGroup) => (countryGroup.countryGroupName as IdLabel).id);
    dispatch(
      updateCountryGroupsValues(
        (error: unknown, ids: string[]) => {
          if (error) {
            return setErrMessage('Something went wrong while saving the country groups');
          }
          FCUSTCOUNTRYGROUP2.push(...ids);
          dispatch(
            createTemplate(
              (err, data) => {
                if (data) {
                  navigate(`/layout/edit/template/${data.id}`);
                } else if (err) {
                  console.log(err);
                  setSaving(false);
                  if (err.response?.data) {
                    setErrMessage(err.response.data);
                  }
                }
              },
              {
                FTITLE: (fieldsFilled[SECOND_STEP] as FormValues).title as string,
                FCUSTTEMPLATETYPE: ((fieldsFilled[FIRST_STEP] as FormValues).type as SelectValue)
                  .id,
                FCUSTEFFECTIVEVERSION: (fieldsFilled[FIRST_STEP] as FormValues)
                  .effectiveVersion as string,
                FDESCRIPTION: (
                  (fieldsFilled[FIRST_STEP] as FormValues).description as string
                )?.trim(),
                FCUSTEFFECTIVEDATE: effectiveDate
                  ? effectiveDate.slice(0, effectiveDate.indexOf('+'))
                  : null,
                FCUSTPRODUCTAREA: (
                  (fieldsFilled[SECOND_STEP] as FormValues).productArea as SelectValue[]
                ).map((item) => item.id),
                FCUSTTEMPLATESTAGE: (
                  (fieldsFilled[SECOND_STEP] as FormValues).stage as SelectValue[]
                ).map((item) => item.id),
                FCUSTREPORTCLASSIFICATION: (fieldsFilled[SECOND_STEP] as FormValues)?.report
                  ? ((fieldsFilled[SECOND_STEP] as FormValues)?.report as SelectValue).id
                  : '',
                FCUSTREPORTTITLE: (
                  (fieldsFilled[SECOND_STEP] as FormValues)?.reportTitle as SelectValue[]
                ).map((el) => el.id),
                FCUSTCTDTITLE: ((fieldsFilled[SECOND_STEP] as FormValues).ctdTitle as SelectValue)
                  .id,
                FCUSTCTDCODE: ((fieldsFilled[SECOND_STEP] as FormValues).ctdCode as SelectValue).id,
                FCUSTBBCODE: (
                  (fieldsFilled[SECOND_STEP] as FormValues).bbCode as SelectValue[]
                ).map((item) => item.id),
                FCUSTCOUNTRYGROUP2,
                FCUSTCOUNTRY: (
                  (fieldsFilled[SECOND_STEP] as FormValues).countries as SelectValue[]
                ).map((item) => item.id),
                FIAPAUTHORLIST: authorList,
                FIAPREVIEWER: reviewerList,
                FCUSTAGREEMENTTYPE: (
                  (fieldsFilled[SECOND_STEP] as FormValues).agreementType as SelectValue
                ).id,
              },
            ),
          );
        },
        countryGroups.filter((countryGroup) => typeof countryGroup.countryGroupName === 'string'),
      ),
    );
  };

  const isStepsCompleted = (): boolean => {
    const newEmptyFields: string[] = [];
    if (activeStep === THIRD_STEP) {
      return true;
    }
    if (!fieldsFilled[activeStep]) {
      return false;
    }
    const optionalFields = [...TEMPLATE_OPTIONAL_FIELDS];
    if (
      ((fieldsFilled[FIRST_STEP] as FormValues).type as SelectValue).id ===
      TEMPLATE_TYPES.ptqagreements
    ) {
      optionalFields.push(...['report', 'reportTitle', 'ctdCode', 'ctdTitle', 'stage', 'bbCode']);
    } else if (
      ((fieldsFilled[FIRST_STEP] as FormValues).type as SelectValue).id ===
      TEMPLATE_TYPES.submission
    ) {
      optionalFields.push(...['report', 'reportTitle']);
    } else {
      optionalFields.push(...['ctdCode', 'ctdTitle']);
    }
    Object.entries(fieldsFilled[activeStep]).forEach(([key, value]) => {
      if (isFieldEmpty(optionalFields, key, value)) {
        newEmptyFields.push(key);
      }
    });
    setEmptyFields(newEmptyFields);
    return !newEmptyFields.length;
  };

  const nextStep = () => {
    if (!isStepsCompleted()) {
      setNextStepClicked(true);
      return;
    }
    if (activeStep < steps.length) {
      setNextStepClicked(false);
      if (activeStep === SECOND_STEP) {
        setResetValuesBasedOnType('keep');
      }
      setActiveStep((prev) => prev + 1);
      if (!completedSteps.includes(activeStep)) {
        setCompletedSteps([...completedSteps, activeStep]);
      }
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
      setResetValuesBasedOnType('keep');
    }
  };

  const fillStepFields = (step: string, value?: FormValues) => {
    if (value) {
      fieldsFilled[step] = value;
      setFieldsFilled({ ...fieldsFilled });
    }
  };

  useEffect(() => {
    if (!user?.isAdmin && !user?.isAuthor) {
      navigate('/layout/templates');
    }
  }, [user]);

  useEffect(() => {
    if (nextStepClicked) {
      isStepsCompleted();
    }
  }, [fieldsFilled]);

  const setCountryGroupValues = (updatedCountryGroups: CountryGoup[]) => {
    setCountryGroups(updatedCountryGroups);
  };

  const getCountriesOfCountryGroup = (countryGroup: IdLabel) => {
    setSaving(true);
    dispatch(
      getTemplateMetadataValues(
        (err, fields: RequiredMetadata[]) => {
          if (!err && fields) {
            const countriesOfCountryGroup = fields.find(
              (field) => field.id === 'FCUSTCOUNTRIESOFCOUNTRYGROUP',
            )?.values[0].value;
            const selectedIndex = countryGroups.findIndex(
              (el) => (el.countryGroupName as IdLabel).id === countryGroup.id,
            );
            if (selectedIndex > -1) {
              countryGroups.splice(selectedIndex, 1);
            }
            setCountryGroups([
              ...countryGroups,
              {
                countryGroupName: countryGroup,
                countries: countriesOfCountryGroup as string[],
                disabled: true,
              },
            ]);

            setSaving(false);
          }
        },
        {
          fields: [
            {
              id: 'FCUSTCOUNTRYGROUP2',
              type: 'multiLov',
              value: [
                {
                  id: countryGroup.id,
                },
              ],
            },
          ],
        },
      ),
    );
  };

  const getStep = () => {
    switch (activeStep) {
      case FIRST_STEP: {
        return (
          <BasicDetails
            title='Enter basic details'
            fillStepFields={fillStepFields}
            fieldsFilled={(fieldsFilled[activeStep] as FormValues) || {}}
            setResetValuesBasedOnType={(val) =>
              val ? setResetValuesBasedOnType(val) : setResetValuesBasedOnType((prev) => !prev)
            }
            emptyFields={emptyFields}
          />
        );
      }
      case SECOND_STEP: {
        return (
          <TemplateData
            title='Enter template data'
            type={((fieldsFilled[1] as FormValues)?.type as SelectValue)?.id}
            fillStepFields={fillStepFields}
            fieldsFilled={(fieldsFilled[activeStep] as FormValues) || {}}
            resetValuesBasedOnType={resetValuesBasedOnType}
            setResetValuesBasedOnType={(val) =>
              val ? setResetValuesBasedOnType(val) : setResetValuesBasedOnType((prev) => !prev)
            }
            countryGroups={countryGroups}
            getCountriesOfCountryGroup={getCountriesOfCountryGroup}
            setCountryGroupValues={setCountryGroupValues}
            emptyFields={emptyFields}
          />
        );
      }
      case THIRD_STEP: {
        return (
          <Review
            fieldsFilled={fieldsFilled}
            setActiveStep={setActiveStep}
            setResetValuesBasedOnType={(val) =>
              val ? setResetValuesBasedOnType(val) : setResetValuesBasedOnType((prev) => !prev)
            }
            resetValuesBasedOnType={resetValuesBasedOnType}
            countryGroups={countryGroups}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className='template'>
      <div className='page-header'>
        <PageTitle
          name='Create new template'
          icon={<ArrowBackIcon />}
          onIconClick={() => {
            if (activeStep > 1) {
              setShowConfirmationPopup(true);
            } else {
              navigate('/layout/templates');
            }
          }}
        />
      </div>
      <div className='page-content'>
        <div className='stepper-container'>
          <div className='project-stepper'>
            <Stepper activeStep={activeStep} steps={steps} completedSteps={completedSteps} />
          </div>
        </div>
        <div className='form'>{getStep()}</div>
        <div className='form-button-container'>
          {activeStep !== FIRST_STEP && (
            <IAButton disabled={saving} className='back-btn' handleClick={prevStep}>
              Back
            </IAButton>
          )}
          <IAButton
            disabled={saving}
            className='main-action-btn'
            handleClick={
              actionButtonContentAndAction(activeStep, saving, nextStep, saveTemplate).handler
            }
          >
            {actionButtonContentAndAction(activeStep, saving, nextStep, saveTemplate).text}
          </IAButton>
        </div>
        {saving && (
          <>
            <div className='loader-container with-bg'>
              <CircularProgress value={30} size='80px' />
            </div>
            <div className='loader-background'></div>
          </>
        )}
      </div>

      <TemplateExistsDialog
        open={templateExistDialog}
        handleClose={() => setTemplateExistDialog(false)}
        existingTemplateName='Template name'
        link='/layout/edit/template/SSIAAAAAAAApR1VJRC0xNzY1RjEzQi0wREUwLTQwRjUtQjYxRi1GMTI0OEY3NUM2QUIFZW4tVVMBMSlHVUlELTYxNTRGQzYxLTI4OTYtNDJBNS1BNjQwLURDMDM3RTk5Qzc2Qg'
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errMessage}
        autoHideDuration={5000}
        onClose={() => setErrMessage('')}
      >
        <Alert
          onClose={() => setErrMessage('')}
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {errMessage}
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        open={showConfirmationPopup}
        handleClose={() => setShowConfirmationPopup(false)}
        mainBtnText='Confirm'
        confirmationText='You are about to go back to main screen'
        descriptionData={[
          'Are you sure you want to go back to main screen?',
          'You will lose all the progress and this action cannot be undone.',
        ]}
        handleSubmit={() => navigate('/layout/templates')}
      />
    </div>
  );
};
