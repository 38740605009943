import { useEffect, useRef, useState } from 'react';
import api from '../../../core/api';
import EditIcon from '../../../components/icons/EditIcon';

export const IAPage = () => {
  const [mapHtml, setMapHtml] = useState({ __html: '' });
  const [conceptHtml, setConceptHtml] = useState({ __html: '' });
  const [mapTitle, setMapTitle] = useState('');
  const myRef = useRef(null);

  const handleMutation = () => {
    document.querySelectorAll('.map li').forEach((li) => {
      li.addEventListener('click', (event) => {
        const innerText = (event.target as HTMLElement).innerText;
        document.getElementById(innerText)?.scrollIntoView();
      });
    });
  };
  useEffect(() => {
    if (myRef.current) {
      const observer = new MutationObserver(handleMutation);
      observer.observe(myRef.current, {
        // Check config in https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
        childList: true,
        attributes: true,
        characterData: true,
      });
    }

    async function createMarkup() {
      const response = await api.post('/proxy/data-transfer/api/output/xmlConverter', {});
      const {
        map: mapHtmlString,
        concepts: conceptHtmlString,
        mapTitle: mapTitleString,
      } = await response.data;

      return { mapHtml: mapHtmlString, conceptHtml: conceptHtmlString, mapTitleString };
    }
    createMarkup().then((result) => {
      setMapHtml({ __html: result.mapHtml });
      setConceptHtml({ __html: result.conceptHtml });
      setMapTitle(result.mapTitleString);
    });
  }, []);

  return (
    <div className='page-content'>
      <div className='title'>
        {mapTitle}
        <div className='link-to-editor'>
          <EditIcon
            onClick={(event) => {
              event.stopPropagation();
              window.open(
                'https://intelligent-authoring-sb.roche.com/layout/edit/document/tBMBAAAAAAApR1VJRC0wQjJCRDAzOC01OEE3LTRFODgtQUY4NC0yQzdFMDhFMzJCQjQFZW4tVVMBMSlHVUlELTkwOTBDOTIyLTFCMEItNDhGQy1BRkYwLUY5MUZCOTEyNDBFMghEb2N1bWVudA/editor',
                '_blank',
              );
            }}
          />
        </div>
      </div>

      <div>
        <div className='map' ref={myRef} dangerouslySetInnerHTML={mapHtml} />
        <div className='concepts' dangerouslySetInnerHTML={conceptHtml} />
      </div>
    </div>
  );
};
