import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { GlobalState } from '../../core/models/state/globalState';
import {
  FieldsFilledInterface,
  FormValues,
  Member,
  IdLabel,
  SelectValue,
  StepFieldsInterface,
} from '../../core/models/global';
import {
  ProductFamilies,
  SelectedProductMetadata,
} from '../../core/models/project/projectInterface';
import { TemplateInterface, Component } from '../../core/models/template/templateInterface';
import Stepper from '../../components/Stepper/Stepper';
import './WorkingDocument.scss';
import IAButton from '../../components/Button/Button';
import BasicDetails from '../../components/DocumentSteps/BasicDetails';
import DocumentData from '../../components/DocumentSteps/DocumentData';
import SelectComponents from '../../components/DocumentSteps/SelectComponents';
import Review from '../../components/DocumentSteps/Review';
import IADialog from '../../components/Dialog/Dialog';
import {
  createWorkingDocument,
  getDocumentRequiredMetadata,
} from '../../state/actions/workingDocument';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';
import {
  filterAdditionalMetadataFields,
  getValue,
  populateFieldsFromProductMetadata,
} from '../../core/services/apiTranformations';
import { getProjectById } from '../../state/actions/project';
import {
  FIRST_STEP,
  SECOND_STEP,
  THIRD_STEP,
  documentAdditionalMetadataAndReports,
} from '../../constants';
import { getIdOfValueFromData, validateProductMetadata } from '../../core/services/productMetadata';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { actionButtonContentAndAction } from '../../core/services/helpers';
import { FieldResponseInterface } from '../../core/models/system/systemDefinition';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
export const WorkingDocument = () => {
  const query = useQuery();
  const projectId = query.get('project') ?? undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    project: projectDefinitions,
    template: templateDefinitions,
    document: documentDefinition,
  } = useSelector((state: GlobalState) => state.system);
  const { documentRequireMetadataFields } = useSelector(
    (state: GlobalState) => state.workingDocument,
  );
  const { project, projectData } = useSelector((state: GlobalState) => state.project);
  const { user } = useSelector((state: GlobalState) => state.user);
  const [activeStep, setActiveStep] = useState<number>(FIRST_STEP);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [template, setTemplate] = useState<TemplateInterface>();
  const [productMetadata, setProductMetadata] = useState<ProductFamilies[]>([]);
  const [projectMetadata, setProjectMetadata] = useState<SelectedProductMetadata>();
  const [components, setComponents] = useState<Component[]>([
    {
      id: 'sds55ds1dd',
      title: 'Batch and scale definition',
      components: [
        { id: 'sds55dsds0dd', title: 'Table S.2.2-1 composition of cell culture' },
        { id: 'sds55dsds7dd', title: 'Table S.2.2 composition of trace elements' },
        { id: 'sds55dsds2dd', title: 'Table S.2.3-composition of RTE 1.2 solution' },
        { id: 'sds55dsds5dd', title: 'Table S.2.4 composition of cell culture' },
      ],
    },
    {
      id: 'sds56ds1dd',
      title: 'Cell culture and harvest',
      components: [
        {
          id: 'sds55dsds0ddd',
          title: 'Table S.2.2-1 composition of cell culture',
          components: [
            {
              id: 'sds55dsdse0dd',
              title: 'Table S.2.2-1 composition of cell culture 2',
            },
            {
              id: 'sds55dsdst7dd',
              title: 'Table S.2.2 composition of trace elements 2',
            },
            {
              id: 'sds55dsds52dddd',
              title: 'Table S.2.3-composition of RTE 1.2 solution 2',
            },
            { id: 'sds55dsds59dd', title: 'Table S.2.4 composition of cell culture 2' },
          ],
        },
        { id: 'sds55dsds7ddd', title: 'Table S.2.2 composition of trace elements' },
        { id: 'sds55dsds2dd', title: 'Table S.2.3-composition of RTE 1.2 solution' },
        { id: 'sds55dsds5dd', title: 'Table S.2.4 composition of cell culture' },
      ],
    },
    {
      id: 'sds57ds1dd',
      title: 'Purification',
      components: [
        { id: 'sds55dsds1dd', title: 'Figure S.2.2-2 Purification process flow' },
        { id: 'sds55vbds1dd', title: 'Figure S.2.2-3 Purification process flow' },
        { id: 'sds55ders1dd', title: 'Description of purification process' },
      ],
    },
  ]);
  const steps = ['Enter basic details', 'Enter document data', 'Review & Save'];
  const [fieldsFilled, setFieldsFilled] = useState<FieldsFilledInterface>({ [FIRST_STEP]: {} });
  const [templatePage, setTemplatePage] = useState(FIRST_STEP);
  const [creationSuccess, setCreationSuccess] = useState(false);
  const [createdDocId, setCreatedDocId] = useState<string>();
  const [saving, setSaving] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [emptyFields, setEmptyFields] = useState<string[]>([]);
  const [nextStepClicked, setNextStepClicked] = useState(false);

  const [additionalMetadata, setAdditionalMetadata] = useState<RequiredMetadata[]>([]);
  const [additionalMetadataValues, setAdditionalMetadataValues] = useState<FormValues>({});
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const getBodyValue = (key: keyof SelectedProductMetadata) => {
    return Array.from(
      new Set(
        ((fieldsFilled[SECOND_STEP] as SelectedProductMetadata)[key] as string[])
          ?.flat(3)
          .filter((el) => el && !el.includes('TEMP')),
      ),
    );
  };

  const saveDocument = () => {
    let creatorId = '';
    const moleculeFields = populateFieldsFromProductMetadata(
      productMetadata,
      getBodyValue('selectedProductFamilies'),
      getBodyValue('selectedDrugProducts'),
    );
    setSaving(true);
    const members =
      ((fieldsFilled[FIRST_STEP] as StepFieldsInterface).members?.value as Member[]) || [];

    let authorList = members
      .filter((member) => member.role.toLowerCase().includes('author'))
      .map((el) => el.value);
    if (!authorList.includes(user.userId)) {
      authorList = [...authorList, user.userId];
    }

    // const projectItem = projectsData.find(
    //   (pr) =>
    //     pr.id ===
    //     ((fieldsFilled[FIRST_STEP] as StepFieldsInterface).selectedProject?.value as string),
    // );
    let FCUSTEVENT: string[] = [],
      FCUSTEVENTTYPE: string[] = [],
      FCUSTEVENTCHANGECATEGORY: string[] = [];
    if (projectData) {
      const field = (projectData.fields as FieldResponseInterface[]).find(
        (item) => item.id === 'FIAPCREATEDBY',
      );
      creatorId = field?.values[0].value as string;
      if (creatorId && !authorList.includes(creatorId)) {
        authorList = [...authorList, creatorId];
      }
      FCUSTEVENT = getIdOfValueFromData(projectData, 'FCUSTEVENT') || [];
      FCUSTEVENTTYPE = getIdOfValueFromData(projectData, 'FCUSTEVENTTYPE') || [];
      FCUSTEVENTCHANGECATEGORY =
        getIdOfValueFromData(projectData, 'FCUSTEVENTCHANGECATEGORY') || [];
    }
    let reviewerList = members
      .filter((member) => member.role.toLowerCase().includes('reviewer'))
      .map((el) => el.value);
    reviewerList = reviewerList.filter((el) => el !== user.userId && el !== creatorId);
    console.log(FCUSTEVENT, FCUSTEVENTTYPE, FCUSTEVENTCHANGECATEGORY);
    const mainMetadata: FormValues = {
      projectId: (fieldsFilled[FIRST_STEP] as StepFieldsInterface).selectedProject?.value as string,
      templateId: (fieldsFilled[FIRST_STEP] as StepFieldsInterface).selectedTemplate
        ?.value as string,
      FTITLE: (fieldsFilled[FIRST_STEP] as StepFieldsInterface).FTITLE?.value as string,
      FDESCRIPTION: (
        (fieldsFilled[FIRST_STEP] as StepFieldsInterface).FDESCRIPTION?.value as string
      )?.trim(),
      // FCUSTMETADATA: productMetadata
      //   ? JSON.stringify(
      //       productMetadata.filter((el) => getBodyValue('selectedProductFamilies').includes(el.id)),
      //     )
      //   : '',
      FCUSTPRODUCTFAMILY: getBodyValue('selectedProductFamilies'),
      FCUSTPRODUCT: getBodyValue('selectedDrugProducts'),
      FCUSTSUBSTANCE: getBodyValue('selectedDrugSubstances'),
      FCUSTPACKAGINGCODE: getBodyValue('selectedPackagingCodes'),
      FCUSTDPMANUFACTURER: getBodyValue('selectedDPManufacturers'),
      FCUSTDSMANUFACTURER: getBodyValue('selectedDSManufacturers'),
      FCUSTEVENT: FCUSTEVENT.filter((el) => getBodyValue('selectedEvents').includes(el)),
      FCUSTEVENTTYPE: FCUSTEVENTTYPE.filter((el) =>
        getBodyValue('selectedEventTypes').includes(el),
      ),
      FIAPAUTHORLIST: authorList,
      FIAPREVIEWER: reviewerList,
    };
    if (template?.FCUSTCTDCODE) {
      const lovField = templateDefinitions.lovs.DCUSTCTDCODE.find(
        (el) => el.label === template?.FCUSTCTDCODE,
      );
      if (lovField) {
        mainMetadata.FCUSTCTDCODE = lovField.id;
      }
    }
    const additionalMetadataValuesFormatted = { ...additionalMetadataValues };
    Object.entries(additionalMetadataValuesFormatted).forEach(([metadataKey, metadataValue]) => {
      if (Array.isArray(metadataValue)) {
        additionalMetadataValuesFormatted[metadataKey] = (metadataValue as SelectValue[]).map(
          (el) => el.id,
        );
      } else if ((metadataValue as SelectValue)?.id) {
        additionalMetadataValuesFormatted[metadataKey] = (metadataValue as SelectValue).id;
      }
    });
    dispatch(
      createWorkingDocument(
        (err, createdDoc) => {
          if (createdDoc) {
            navigate(`/layout/edit/document/${createdDoc?.id}/editor`);
            setCreatedDocId(createdDoc?.id as string);
          } else if (err) {
            console.log(err);
            setSaving(false);
            if (err.response?.data) {
              setErrMessage(err.response.data);
            }
          }
        },
        {
          data: {
            ...mainMetadata,
            ...moleculeFields,
            ...additionalMetadataValuesFormatted,
          },
        },
      ),
    );
  };

  const isStepsCompleted = (): boolean => {
    let newEmptyFields: string[] = [];
    if (activeStep === THIRD_STEP) {
      return true;
    }
    if (activeStep === FIRST_STEP && fieldsFilled[activeStep]) {
      const data = fieldsFilled[activeStep] as StepFieldsInterface;
      Object.entries(data).forEach(([key, item]) => {
        if (item.required && !(item.value as string)?.trim()) {
          newEmptyFields.push(key);
        }
      });
    }
    if (activeStep === SECOND_STEP && fieldsFilled[activeStep]) {
      const passedValue = fieldsFilled[activeStep] as SelectedProductMetadata;
      newEmptyFields = validateProductMetadata(passedValue || [], newEmptyFields);
    }
    setEmptyFields(newEmptyFields);
    return !newEmptyFields.length;
  };

  const nextStep = () => {
    if (!isStepsCompleted()) {
      setNextStepClicked(true);
      return;
    }
    if (activeStep < steps.length) {
      document.getElementsByClassName('page-header')?.[0]?.scrollIntoView();
      const { selectedProject, selectedTemplate } = fieldsFilled[1] as StepFieldsInterface;
      if (activeStep === FIRST_STEP) {
        setAdditionalMetadata([]);
        setAdditionalMetadataValues({});
        dispatch(
          getDocumentRequiredMetadata(() => {}, {
            projectId: selectedProject.value as string,
            templateId: selectedTemplate.value as string,
            fields: [],
          }),
        );
      }
      setNextStepClicked(false);
      setActiveStep((prev) => prev + 1);
      if (!completedSteps.includes(activeStep)) {
        setCompletedSteps([...completedSteps, activeStep]);
      }
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const fillStepFields = (
    step: number,
    value?: StepFieldsInterface | SelectedProductMetadata,
    templateObj?: TemplateInterface,
    componentsArr?: Component[],
  ) => {
    if (value) {
      fieldsFilled[step] = value;
      setFieldsFilled({ ...fieldsFilled });
    }
    if (templateObj) {
      setTemplate({ ...templateObj });
    }
    if (componentsArr) {
      setComponents([...componentsArr]);
    }
  };

  const setAdditionalMetadataValuesFromDerivedFields = (field: RequiredMetadata) => {
    if (!additionalMetadata.find((el) => el.id === field.id)) {
      return;
    }
    if (field.definition.type === 'string') {
      setAdditionalMetadataValues((prevValue) => {
        return {
          ...prevValue,
          [field.id]: getValue(field, 'label') as string,
        };
      });
    } else {
      setAdditionalMetadataValues((prevValue) => {
        return {
          ...prevValue,
          [field.id]: field.values.map((el) => ({
            id: (el.value as SelectValue).id,
            label: (el.value as SelectValue).label,
          })),
        };
      });
    }
  };

  const getFieldValues = (id: string, value: string | SelectValue | SelectValue[]) => {
    const { selectedProject, selectedTemplate } = fieldsFilled[1] as StepFieldsInterface;
    dispatch(
      getDocumentRequiredMetadata(
        (err: { response: { data: string } } | null, fields: RequiredMetadata[]) => {
          if (err) {
            return;
          }
          fields
            .filter((field) => !field.definition.flags?.isRoot && field.values)
            .forEach((field) => {
              setAdditionalMetadataValuesFromDerivedFields(field);
            });
        },
        {
          projectId: selectedProject.value as string,
          templateId: selectedTemplate.value as string,
          fields: [
            {
              id,
              type: 'multiLov',
              value: (value as IdLabel[]).map((el) => ({ id: el.id })),
            },
          ],
        },
      ),
    );
  };

  const handleAdditionalMetadataChange = (
    id: string,
    value: string | SelectValue | SelectValue[],
  ) => {
    console.log(id, value);
    setAdditionalMetadataValues((prevValue) => {
      return {
        ...prevValue,
        [id]: value,
      };
    });
    console.log();
    const fieldDefinition = documentRequireMetadataFields.find(
      (field) => field.id === id,
    )?.definition;
    if (fieldDefinition && fieldDefinition.flags?.isLeaf === false) {
      getFieldValues(id, value);
    }
  };

  useEffect(() => {
    if (!user?.isAdmin && !user?.isAuthor) {
      navigate('/layout/working-documents');
    }
  }, [user]);

  useEffect(() => {
    if (projectId && project?.id !== projectId) {
      dispatch(getProjectById(() => {}, projectId));
    }
  }, [project, projectId]);

  useEffect(() => {
    if (nextStepClicked) {
      isStepsCompleted();
    }
  }, [fieldsFilled]);

  useEffect(() => {
    if (template) {
      const tempAdditionalMetadata = filterAdditionalMetadataFields(
        documentAdditionalMetadataAndReports,
        documentRequireMetadataFields,
        template,
      );
      setAdditionalMetadata(tempAdditionalMetadata);
    }
  }, [documentRequireMetadataFields]);

  const getStep = () => {
    switch (activeStep) {
      case FIRST_STEP: {
        return (
          <BasicDetails
            title='Enter basic details'
            projectFields={projectDefinitions?.fields}
            projectLovs={projectDefinitions?.lovs}
            templateFields={templateDefinitions?.fields}
            projectId={projectId}
            fillStepFields={fillStepFields}
            fieldsFilled={(fieldsFilled[activeStep] as StepFieldsInterface) || {}}
            fieldsFilledMetadata={(fieldsFilled[SECOND_STEP] as SelectedProductMetadata) || {}}
            page={templatePage}
            setPage={setTemplatePage}
            handleProjectMetadata={(data) => setProjectMetadata(data)}
            emptyFields={emptyFields}
          />
        );
      }
      case SECOND_STEP: {
        return (
          <DocumentData
            title='Enter document data'
            subtitle='Streamline your automation by entering relevant data'
            fillStepFields={fillStepFields}
            fieldsFilled={(fieldsFilled[activeStep] as SelectedProductMetadata) || {}}
            giveProductMetadata={(data: ProductFamilies[]) => setProductMetadata(data)}
            productMetadata={productMetadata}
            projectMetadata={projectMetadata}
            emptyFields={emptyFields}
            additionalMetadata={additionalMetadata}
            additionalMetadataValues={additionalMetadataValues}
            documentLovs={documentDefinition.lovs}
            handleAdditionalMetadataChange={handleAdditionalMetadataChange}
            projectId={
              ((fieldsFilled[FIRST_STEP] as StepFieldsInterface)?.selectedProject
                ?.value as string) || ''
            }
            templateId={
              ((fieldsFilled[FIRST_STEP] as StepFieldsInterface)?.selectedTemplate
                ?.value as string) || ''
            }
          />
        );
      }
      case THIRD_STEP: {
        return (
          <Review
            template={template}
            components={components}
            projectFields={projectDefinitions?.fields}
            projectLovs={projectDefinitions?.lovs}
            templateFields={templateDefinitions?.fields}
            fillStepFields={fillStepFields}
            fieldsFilled={fieldsFilled}
            setActiveStep={setActiveStep}
            additionalMetadata={additionalMetadata}
            additionalMetadataValues={additionalMetadataValues}
            productMetadata={productMetadata}
            projectMetadata={projectMetadata}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const goToComponents = () => {
    setCreationSuccess(false);
    setActiveStep(steps.length + 1);
  };

  return (
    <div className='working-document'>
      <div className='page-header'>
        <PageTitle
          name={
            activeStep !== steps.length + 1
              ? 'Create new document'
              : 'Select Components from template'
          }
          icon={<ArrowBackIcon />}
          onIconClick={() => {
            if (activeStep > 1) {
              setShowConfirmationPopup(true);
            } else if (projectId) {
              navigate(`/layout/workspaces/${projectId}`);
            } else {
              navigate('/layout/working-documents');
            }
          }}
        />
      </div>
      <div className='page-content'>
        {activeStep !== steps.length + 1 ? (
          <>
            <div className='stepper-container'>
              <div className='working-document-stepper'>
                <Stepper activeStep={activeStep} steps={steps} completedSteps={completedSteps} />
              </div>
            </div>
            <div className='form'>{getStep()}</div>
            <div className='form-button-container'>
              {activeStep !== FIRST_STEP && (
                <IAButton className='back-btn' handleClick={prevStep} disabled={saving}>
                  Back
                </IAButton>
              )}
              <IAButton
                disabled={saving}
                className='main-action-btn'
                handleClick={
                  actionButtonContentAndAction(activeStep, saving, nextStep, saveDocument).handler
                }
              >
                {actionButtonContentAndAction(activeStep, saving, nextStep, saveDocument).text}
              </IAButton>
            </div>
          </>
        ) : (
          <>
            <SelectComponents
              title='Select Components from the template'
              subtitle={template?.FTITLE?.constructor === String ? template.FTITLE : ''}
              template={template}
              components={components}
              fillStepFields={fillStepFields}
            />
            <div className='form-button-container'>
              <IAButton
                className='main-action-btn'
                handleClick={() => navigate(`/layout/edit/document/${createdDocId}/editor`)}
              >
                Save
              </IAButton>
            </div>
          </>
        )}
        {saving && (
          <>
            <div className='loader-container with-bg'>
              <CircularProgress value={30} size='80px' />
            </div>
            <div className='loader-background'></div>
          </>
        )}
      </div>
      <IADialog
        title='Document created successfully'
        open={creationSuccess}
        handleClose={() => {}}
        xButton={false}
        mainBtnText='Yes'
        handleSubmit={() => goToComponents()}
        maxWidth='xs'
        customButton={
          <IAButton
            className='back-btn'
            handleClick={() => navigate(`/layout/edit/document/${createdDocId}/editor`)}
            data-testid='skip-button'
          >
            Skip
          </IAButton>
        }
      >
        <p className='creation-text'>To start with would you like to finalise the components?</p>
      </IADialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errMessage}
        autoHideDuration={5000}
        onClose={() => setErrMessage('')}
      >
        <Alert
          onClose={() => setErrMessage('')}
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {errMessage}
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        open={showConfirmationPopup}
        handleClose={() => setShowConfirmationPopup(false)}
        mainBtnText='Confirm'
        confirmationText='You are about to go back to main screen'
        descriptionData={[
          'Are you sure you want to go back to main screen?',
          'You will lose all the progress and this action cannot be undone.',
        ]}
        handleSubmit={() => {
          if (projectId) {
            navigate(`/layout/workspaces/${projectId}`);
          } else {
            navigate('/layout/working-documents');
          }
        }}
      />
    </div>
  );
};
