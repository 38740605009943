import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { UserInteface } from '../../core/models/user/user';
import './Pages.scss';

export const IAPagesLayout = () => {
  const user = useLoaderData() as UserInteface;

  return (
    <div>
      <Header user={user} />
      <div className='content'>
        <Outlet />
      </div>
    </div>
  );
};
