const dateFields = [
  {
    id: 'CREATED-ON',
    label: '',
  },
  {
    id: 'MODIFIED-ON',
    label: '',
  },
];
export const defaultTableHeadCells = {
  project: [
    {
      id: 'FTITLE',
      label: '',
    },
    ...dateFields,
  ],
  document: [
    {
      id: 'FTITLE',
      label: '',
    },
    {
      id: 'VERSION',
      label: '',
    },
    {
      id: 'FIAPDOCUMENTSTATUS',
      label: '',
    },
    ...dateFields,
  ],
  template: [
    {
      id: 'FCUSTCTDCODE',
      label: '',
    },
    {
      id: 'FCUSTCTDTITLE',
      label: '',
    },
    {
      id: 'FCUSTBBCODE',
      label: '',
    },
    {
      id: 'FCUSTPRODUCTAREA',
      label: '',
    },
    {
      id: 'FCUSTTEMPLATESTAGE',
      label: '',
    },
    {
      id: 'VERSION',
      label: '',
    },
    {
      id: 'FIAPTEMPLATESTATUS',
      label: '',
    },
  ],
  output: [
    {
      id: 'FTITLE',
      label: 'Output title',
    },
    {
      id: 'FISHOUTPUTFORMATREF',
      label: 'Format',
    },
    ...dateFields,
  ],
  transferredOutput: [
    {
      id: 'FTITLE',
      label: 'Output title',
    },
    {
      id: 'DOCUMENTNUMBER',
      label: 'Document Number',
    },
    {
      id: 'VERSION',
      label: 'IAP Version',
    },
    {
      id: 'FISHOUTPUTFORMATREF',
      label: 'Format',
    },
    {
      id: 'SYSTEM',
      label: 'Document management system',
    },
    {
      id: 'TRANSFERREDBY',
      label: 'Transferred By',
    },
    {
      id: 'TRANSFERSTATUS',
      label: 'Status',
    },
    ...dateFields,
  ],
};

export const memberHeadCell = {
  id: 'MEMBERS',
  label: 'Members',
  type: 'string',
};
export const templateHeadCells = [
  'FCUSTPRODUCTAREA',
  'FCUSTTEMPLATESTAGE',
  'FCUSTBBCODE',
  'VERSION',
];

export const documentAdditionalMetadataAndReports: { [key: string]: string[] } = {
  FCUSTMOLECULEINNDPLONG: ['*', 'inn'],
  FCUSTMOLECULEINNDPSHORT: ['*', 'inn'],
  FCUSTMOLECULEINNDPTITLE: ['*', 'inn'],
  FCUSTMOLECULEINNDSLONG: ['*', 'inn'],
  FCUSTMOLECULEINNDSSHORT: ['*', 'inn'],
  FCUSTMOLECULEINNDSTITLE: ['*', 'inn'],
  FCUSTDEVICE2: ['*'],
  FCUSTLYO2: ['*'],
  FCUSTCOUNTRY: ['S*'],
  FCUSTPROJECTVERSION: ['QRA', 'S.2.3', 'S.2.6', 'S.3.2'],
  FCUSTPROJECTVERSIONID: ['QRA', 'S.2.3', 'S.2.6', 'S.3.2'],
  FCUSTPROJECTVERSIONDESC: ['QRA', 'S.2.3', 'S.2.6', 'S.3.2'],
  FCUSTSTARTINGMAT: ['QRA', 'S.2.3', 'S.2.6', 'S.3.2'],
  FCUSTSTARTINGMATNAME: ['QRA', 'S.2.3', 'S.2.6', 'S.3.2'],
  FCUSTSTARTINGMATROCHENUM: ['QRA', 'S.2.3', 'S.2.6', 'S.3.2'],
  FCUSTIMPURITIES: ['QRA', 'S.2.3', 'S.2.6', 'S.3.2'],
  FCUSTIMPURITIESROCHENUM: ['QRA', 'S.2.3', 'S.2.6', 'S.3.2'],
  FCUSTBATCHNUM: ['S.4.4', 'P.5.4', 'S.7.3', 'P.8.3'],
  FCUSTDRUGPRODFORMULATION: ['P.5.4'],
  FCUSTVALIDRESULTDATADOC: ['MVR'],
  FCUSTMETHODNUMBER: ['MVR'],
  FCUSTVALIDATIONREPORT: ['MVR'],
  FCUSTVALIDATIONPLAN: ['MVR'],
  FCUSTVALIDATIONNAME: ['MVR'],
  FCUSTRECEIVINGLABS: ['MVR'],
  FCUSTDONORLAB2: ['MVR'],
  FCUSTMETHOD: ['MVR'],
  FCUSTMETHODSHORT: ['MVR'],
  FCUSTIMPURITYNAME: ['MVR'],
  FCUSTPRODUCTDETAILEDNAME: ['MVR'],
  FCUSTMATERIALNUMBER: ['MVR'],
  FCUSTSAMPLEBULKNAME: ['MVR'],
  FCUSTSPECIFICATIONDOCUMENT: ['MVR'],
  FCUSTSAMPLEBULKLOCALNAME: ['MVR'],
  FCUSTSZRELVARIENTMETHOD: ['PPQ', 'S.2.5', 'OneDoc'],
  FCUSTCGRELVARIENTMETHOD: ['PPQ', 'S.2.5', 'OneDoc'],
  FCUSTHCPCONTENTMETHOD: ['PPQ', 'S.2.5', 'OneDoc'],
  FCUSTHOSTCELLDNAMETHOD: ['PPQ', 'S.2.5', 'OneDoc'],
  FCUSTLEACHEDPROTEINAMETHOD: ['PPQ', 'S.2.5', 'OneDoc'],
  FCUSTINTERNALREPORTS: ['PPQ', 'S.2.5', 'OneDoc'],
  FCUSTMSAGSTUDYTYPE: ['PTQ'],
  FCUSTMATERIALCLASS: ['PTQ'],
  FCUSTECSNETWORK: ['PTQ'],
  FCUSTCLINICALCONTRACT: ['PTQ'],
  FCUSTEUANDUK: ['PTQ'],
  FCUSTSPONSORNAME: ['PTQ'],
  FCUSTSPONSORADDRESS: ['PTQ'],
  FCUSTSPONSORNAMESHORT: ['PTQ'],
};
export const FIRST_STEP = 1;
export const SECOND_STEP = 2;
export const THIRD_STEP = 3;
export const FLATTEN_ARRAY_DEPTH = 3;

export const TYPES_NO_FILTERING = ['userReference', 'dateTime'];

export const DEFAULT_ORDER = {
  id: 'MODIFIED-ON',
  order: 'Descending',
};

export const TEMPLATE_OPTIONAL_FIELDS = [
  'effectiveVersion',
  'effectiveDate',
  'description',
  'countryGroupName',
  'countries',
  'members',
  'agreementType',
];

export const formatsToTransfer = ['PDF', 'DOCX'];
