import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { UserInteface } from '../../core/models/user/user';
import './Pages.scss';
import IASearch from '../../components/Search/Search';

export const IAPages = () => {
  const user = useLoaderData() as UserInteface;
  const navigate = useNavigate();

  const navigateToPage = (slug: string) => {
    navigate(`/pages/${slug}`);
  };
  return (
    <div>
      <div className='title'>Welcome to IAP Pages</div>
      <IASearch handleSearch={(value: string) => {}} />
      <div className='list'>
        <ul>
          <li>
            <a
              onClick={() => {
                navigateToPage('doc-1');
              }}
            >
              Doc 1
            </a>
          </li>
          <li>
            <a href=''>Doc 2</a>
          </li>
          <li>
            <a href=''>Doc 3</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
